import I18n from 'i18n';
import debounce from 'lodash/debounce';
import CardBinder from 'src/data_binders/card_binder';
import CommentBinder from 'src/data_binders/comment_binder';
import Snack from '@effectivastudio/snackbar';
import { $d } from 'src/utils';
import triggerEvent from 'src/lib/utils/trigger_event';
import { modifyEventDate } from 'src/cards_event';

const triggerContentUpdate = () => triggerEvent(document, 'content:update');

const handleSchedulePostContent = (target) => {
  if (!target) { return; }

  const { checked } = target;
  const schedulePostDate = document.querySelector('.js-schedule-post-date');

  schedulePostDate.hidden = !checked;
  $('.js-schedule-post-date :input').attr('disabled', checked ? null : true);
};

$(document)
  .on('click', '.js-comment', function onClickComment(e) {
    e.preventDefault();
    $(this.getAttribute('href')).collapse('toggle');
  })

  // Toggle Like and Bookmark
  .on('ajax:beforeSend', '.card', function onBeforeSendCardBinder(e) {
    const l = e.target.classList;

    if (l.contains('ajax')) { return false; }
    l.remove('xhr-completed');
    l.add('ajax');

    if (l.contains('js-toggle-bookmark')) {
      CardBinder(this, { isBookmarked: 'toggle' });
    }

    if (l.contains('js-toggle-subscribe')) {
      CardBinder(this, { isSubscribed: 'toggle' });
    }

    if (l.contains('js-toggle-pin')) {
      CardBinder(this, { isPinned: 'toggle' });
    }

    if (l.contains('js-toggle-attend')) {
      CardBinder(this, { isAttended: 'toggle', attendType: e.target.dataset.attendType });
    }

    /* Subscribe user to card if performing card actions unless user already subscribed/unsubscribed in the past */
    if (l.contains('js-toggle-bookmark') || l.contains('js-toggle-attend') || l.contains('js-date-vote') || l.contains('js-organize-assign') || l.contains('js-card-comments')) {
      if (this.dataset.isInitSubscriber !== 'true') {
        CardBinder(this, { isSubscribed: 'toggle' });
      }
    }

    return true;
  })

  .on('ajax:success', '.card', function onSuccessCardBinder(e) {
    const t = e.target;
    const l = t.classList;

    if (l.contains('js-toggle-subscribe')) {
      CardBinder(this, { snackMsg: 'subscribe' });
    }

    if (l.contains('js-toggle-bookmark')) {
      CardBinder(this, { snackMsg: 'bookmark' });
    }

    if (l.contains('js-toggle-pin')) {
      CardBinder(this, { snackMsg: 'pin' });
    }

    if (l.contains('js-toggle-attend')) {
      const el = e.target;
      CardBinder(this, { snackMsg: 'attend', attendMsg: el.dataset.attendMsg, attendType: el.dataset.attendType });
    }

    if (l.contains('js-poll-vote')) {
      CardBinder(this, { snackMsg: 'poll_vote' });
    }
  })

  .on('ajax:error', '.card', function onErrorCardBinder(e) {
    const [response, , xhr] = e.detail;

    if (xhr.status === 0) { return; }

    const l = e.target.classList;

    const snackMsg = 'error';

    if (l.contains('js-toggle-bookmark')) {
      CardBinder(this, { isBookmarked: 'toggle', snackMsg });
    }

    if (l.contains('js-toggle-subscribe')) {
      CardBinder(this, { isSubscribed: 'toggle', snackMsg });
    }

    if (l.contains('js-toggle-pin')) {
      CardBinder(this, { isPinned: 'toggle', snackMsg });
    }

    if (l.contains('js-toggle-attend')) {
      CardBinder(this, { isAttended: 'toggle', attendType: 'error', target: $(this).find('.btn-participate'), snackMsg, ...response });
    }
  })

  .on('ajax:complete ajax:stopped', '.card', debounce((e) => {
    // Remove ajax class from clicked link
    e.target.classList.remove('ajax');
    e.target.classList.add('xhr-completed');
  }, 100))

  .on('click', '.js-toggle-card-content', function onToggleContentCardBinder() {
    $(this).closest('.card').removeClass('card-collapsed');
  })

  .on('cocoon:before-insert', '.js-poll-cocoon, .js-date-cocoon, .js-organize-cocoon', function onCocoonBeforeInsertCardBinder(e, $insertedItem) {
    const priorities = $('.nested-fields-priority [type="number"]', this).map((i, element) => element.value).get();
    priorities.push(0);

    const priority = Math.max.apply(this, priorities);
    $insertedItem.find('.nested-fields-priority [type="number"]').val(priority + 1);

    const $toggleTime = $insertedItem.find('[data-visibility-id="datetime-time"]');
    $toggleTime.length && $toggleTime.toggleClass('hidden', !e.currentTarget.querySelector('[data-visibility-target="datetime-time"]').checked);
  })

  .on('cocoon:before-remove', '.js-poll-cocoon, .js-date-cocoon, .js-organize-cocoon', function onCocoonBeforeRemoveCardBinder(e) {
    const cocoonMin = (e.currentTarget.dataset.cocoonMin ? Number(e.currentTarget.dataset.cocoonMin) : 2);
    if ($('.nested-fields', this).length === cocoonMin) {
      e.preventDefault();
    }
  })

  .on('cocoon:after-remove cocoon:after-insert', '.js-poll-cocoon, .js-date-cocoon, .js-organize-cocoon', function onCocoonAfterCardBinder(e) {
    const cocoonMin = (e.currentTarget.dataset.cocoonMin ? Number(e.currentTarget.dataset.cocoonMin) : 2);
    const $el = $(this);
    const k = 'cocoon-disable-remove';

    $('.nested-fields:visible', this).length <= cocoonMin ? $el.addClass(k) : $el.removeClass(k);
  })

  .on('change', '.js-schedule-post', e => handleSchedulePostContent(e.target))

  .on('ionModalWillPresent', 'body', (e) => {
    handleSchedulePostContent(e.target.querySelector('.js-schedule-post'));
  });

function formDataToJson(formData) {
  const object = {};
  if (!formData) { return object; }

  [...formData.entries()].forEach(([key, value]) => {
    object[key] = value;
  });

  return object;
}

function parseQuery(query) {
  const out = {};

  query.replace('?', '').split('&').forEach((pair) => {
    const [key, value] = pair.split('=');
    value && value.trim() && (out[key] = value);
  });

  return out;
}

App.lastSync = +new Date();
let workerTimeout;
let fails = 0;

function worker(opts = {}) {
  // set in .env file SYNC_WORKER_DISABLED=1 to disable worker
  if (App.config.env.SYNC_WORKER_DISABLED) { return; }

  opts.reset && (fails = 0);
  clearTimeout(workerTimeout);
  if (!App.config.user.id) { return; }
  if (!this.pageIsActive) { return; }
  if (App.isSuspended) { return; }

  const query = {};

  const $queries = $('[data-query-name]');

  // if(!$queries.length){ return; }

  const userIdsSet = {};
  document.querySelectorAll('[data-user-status]').forEach((n) => {
    userIdsSet[n.dataset.userStatus] = 1;
  });

  const userIds = Object.keys(userIdsSet);
  const allUsers = userIds.concat([...window.OnlineStatus.all]);

  allUsers.length && (query.online_user_status = [...new Set(allUsers)].join('-'));

  $queries.each(function onEach() {
    const link = this.querySelector('.js-load-before');
    const form = document.querySelector('.js-filter-form-modal');
    const formData = form ? new FormData(form) : new FormData();

    const name = this.dataset.queryName;
    query[name] = $.extend(formDataToJson(formData), link && parseQuery(decodeURIComponent(link.search)));
  });

  const workerId = workerTimeout;

  $.ajax({
    url: '/sync/all.json',
    data: query,
    beforeSend() {
      App.lastSync = +new Date();
    },
  })
    .done((data) => {
      workerId === workerTimeout && triggerEvent(document, 'sync', data);
      // App.syncCount = App.syncCount + 1
      // console.log(App.syncCount)
      fails = 0;
    })
    .fail(() => {
      fails += 1;
    })
    .always((data) => {
      if (data.status === 401) { return; }
      if (fails > 2) { return; }
      workerTimeout = setTimeout(worker, App.config.inTestEnv ? 1000 : 5000);
    });
}

let fullStop = false;

function workerStop() {
  clearTimeout(workerTimeout);
}

function workerFullStop() {
  workerStop();
  fullStop = true;
}

window.workerFullStop = workerFullStop;

function workerStart() {
  if (!fullStop) {
    workerStop();
    workerTimeout = setTimeout(worker, 200);
  }
}

window.workerStart = workerStart;

function setupPusherForCard(channel) {
  channel.bind('comment:create', (data) => {
    const $comment = $(data.html);
    if (data.parent_id) {
      $d('.card-comment', data.parent_id)
        .trigger('comment:add')
        .find('.js-comments-list:first')
        .append($comment);
    } else {
      $d('.card', data.card_id)
        .find('.js-comments-list:first')
        .prepend($comment)
        .trigger('comment:add');
    }
    triggerContentUpdate();
  });

  channel.bind('comment:destroy', (data) => {
    $d('.card-comment', data.id).hide(400).trigger('comment:remove', data.count);
    triggerContentUpdate();
  });

  channel.bind('comment:update', (data) => {
    CommentBinder($d('.card-comment', data.id), data);
    triggerContentUpdate();
  });

  channel.bind('card:date_update', (data) => {
    CardBinder($d('.card', data.id), data);
    triggerContentUpdate();
  });
}

$(document).on('pusher:connected', () => {
  workerStart({ reset: true });
});

document.addEventListener('turbolinks:load', () => {
  fails = 0;
  workerStart();
  // App.syncCount = 0;
});

// Stop sync workers if page is idle
document.addEventListener('visibility', (e) => {
  e.detail.pageIsActive ? workerStart() : workerStop();
});

// const unread_messages_count = '/';

$(document).on('sync', (e) => {
  const data = e.detail;

  if (data.new_cards_available) {
    setTimeout(() => {
      const ids = $('.card').pluck('id', id => parseInt(id, 10));
      const diff = $(data.new_cards_available).not(ids).get();
      diff.length && $('.js-load-before').addClass('reveal');
    }, 400);
  }

  $('.js-community-cards-count .badge')[data.since_last_login_community_cards_count ? 'fadeIn' : 'fadeOut']().html(data.since_last_login_community_cards_count || 0);
  $('.js-support-cards-count .badge')[data.since_last_login_support_cards_count ? 'fadeIn' : 'fadeOut']().html(data.since_last_login_support_cards_count || 0);
  $('.js-marketplace-cards-count .badge')[data.since_last_login_marketplace_cards_count ? 'fadeIn' : 'fadeOut']().html(data.since_last_login_marketplace_cards_count || 0);
  $('.js-event-cards-count .badge')[data.since_last_login_event_cards_count ? 'fadeIn' : 'fadeOut']().html(data.since_last_login_event_cards_count || 0);
});

$(document)
  .on('card.create.fm', (e, data) => {
    const wrapper = document.querySelector('.js-cards-list');
    const $card = $(data.html).addClass('injected');

    if (!wrapper) {
      // development environment renders comments to denote template name, so we must skip them to get the right html element
      const cardId = $card[0]?.dataset?.id || $card[2]?.dataset?.id;

      // check if we are on cards details page and load the new card
      if (window.location.pathname.match(/\w+_cards\/\d+$/) && cardId) {
        window.Turbolinks.visit(cardId);
        return;
      }
      window.Turbolinks.clearCache();
      window.Turbolinks.visit(window.location);
      return;
    }

    const wrapperCollectionCount = Number(wrapper?.dataset.collectionCount);

    if (wrapperCollectionCount === 0) {
      window.Turbolinks.clearCache();
      window.Turbolinks.visit(window.location);
      return;
    }

    wrapper.setAttribute('data-collection-count', wrapperCollectionCount + 1);

    const $firstCard = $('.js-cards-list .card:first');
    const $firstXsCard = $('.js-cards-list xs-card-v2:first');

    modifyEventDate($card.find('.js-event-date')[0]);

    if ($firstCard.length) {
      $firstCard.before($card);
    } else if ($firstXsCard.length) {
      $firstXsCard.before($card);
    } else {
      $('.js-cards-list').append($card);
    }
  })
  .on('card.update.fm', (e, data) => {
    const $originalCard = $d('.card', data.id);
    const $card = $(data.html).addClass('injected');
    $originalCard.hasClass('card-chain-next') && $card.addClass('card-chain-next');
    modifyEventDate($card.find('.js-event-date')[0]);
    $originalCard.replaceWith($card);
  })
  .on('turbolinks:load load_more:done load_before:done', () => {
    const el = document.querySelectorAll('.js-event-date');
    if (!el.length) { return; }

    el.forEach((item) => {
      modifyEventDate(item);
    });
  })
  .on('confirm:complete', '.js-delete', function onConfirmComplete(e) {
    const isConfirmed = e.detail[0];

    if (isConfirmed) {
      const $self = $(this);
      const { id } = e.currentTarget.closest('.card').dataset;
      const isFullView = window.location.href.includes(`_cards/${id}`);

      if (isFullView) {
        window.Turbolinks.clearCache();
        document.querySelector('.js-back-or-default').click();
        return;
      }

      const wrapper = this.closest('.js-cards-list');
      const wrapperCollectionCount = Number(wrapper?.dataset?.collectionCount);

      if (wrapperCollectionCount === 1) {
        window.Turbolinks.clearCache();
        window.location.reload();
        return;
      }

      const card = $(this).closest('.card');

      if (card.hasClass('card-chain-next')) {
        card.next().removeClass('card-smart-collapsed card-chain-prev');
        card.next().addClass('card-chain-next');
      }

      card.remove();
      wrapper && wrapper.setAttribute('data-collection-count', wrapperCollectionCount - 1);

      Snack({
        text: $self.hasClass('js-delete') ? I18n.t('card.action.deleted') : I18n.t('card.action.hidden'),
        actionText: '<i class="icon-close"></i>',
      });
    }
  })
  .on('confirm:complete', '.js-report', function onConfirmReport(e) {
    const isConfirmed = e.detail[0];

    if (isConfirmed) {
      $(this).addClass('active');
      Snack({
        text: I18n.t('card.action.reported'),
        actionText: '<i class="icon-close"></i>',
      });
    }
  });

// New transitional card pusher setup
document.addEventListener('card-pusher-subscribed', (e) => {
  setupPusherForCard(e.detail.channel);
});
