import I18n from 'i18n';
import Snack from '@effectivastudio/snackbar';
import DataBinder from 'src/lib/data_binder';
// import MarketBinder from './market_binder';

function CardSchema(d) {
  if (d.isBookmarked === 'toggle') {
    d.isBookmarked = !this.hasClass('.js-toggle-bookmark', 'active');
    d.isBookmarkedText = I18n.t(d.isBookmarked ? 'card.unbookmark.title' : 'card.bookmark.title');
  }

  if (d.isSubscribed === 'toggle') {
    d.isSubscribed = !this.hasClass('.js-toggle-subscribe', 'active');
    d.isSubscribedText = I18n.t(d.isSubscribed ? 'card.unsubscribe.title' : 'card.subscribe.title');
    this.el.dataset.isInitSubscriber = 'true';
  }

  if (d.isPinned === 'toggle') {
    d.isPinned = !this.hasClass('.js-toggle-pin', 'active');
    d.isPinnedText = I18n.t(d.isPinned ? 'card.unpin.title' : 'card.pin.title');
  }

  if ('date_votes_count' in d) {
    d.date_votes_count = I18n.t('voting.x_members_voted', { count: d.date_votes_count });
  }

  if (d.snackMsg) {
    if (d.snackMsg === 'subscribe') {
      Snack({ text: I18n.t(this.hasClass('.js-toggle-subscribe', 'active') ? 'card.subscribe.success_msg' : 'card.unsubscribe.success_msg') });
    } else if (d.snackMsg === 'bookmark') {
      Snack({ text: I18n.t(this.hasClass('.js-toggle-bookmark', 'active') ? 'card.bookmark.success_msg' : 'card.unbookmark.success_msg') });
    } else if (d.snackMsg === 'date_vote') {
      Snack({ text: I18n.t('snack.vote.success_msg') });
    } else if (d.snackMsg === 'pin') {
      Snack({ text: I18n.t(this.hasClass('.js-toggle-pin', 'active') ? 'card.pin.success_msg' : 'card.unpin.success_msg') });
    } else if (d.snackMsg === 'error') {
      Snack({
        text: I18n.t('snack.generic_error_msg'),
        customClass: 'snackbar-danger',
      });
    } else {
      Snack({
        text: d.snackMsg,
        customClass: 'snackbar-danger',
      });
    }
  }

  this
    .data(':root', 'stamp', d.updated_at)
    .toggleClass('.js-toggle-like', 'active', d.isLiked)
    .toggleClass('.js-toggle-bookmark', 'active', d.isBookmarked)
    .toggleClass('.js-toggle-subscribe', 'active', d.isSubscribed)
    .toggleClass('.js-toggle-pin', 'active', d.isPinned)
    // .toggleClass('.card-marketplace-price > span', 'hidden', d.isAvailable)
    // .toggleClass(':root', 'pinned', d.isPinned)
    .toggleClass('.js-comment', 'active', d.hasComments)
    .text('.js-toggle-bookmark span', d.isBookmarkedText)
    .text('.js-toggle-subscribe span', d.isSubscribedText)
    .text('.js-toggle-pin span', d.isPinnedText);
}

export default DataBinder.create(CardSchema);
