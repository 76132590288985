import I18n from 'i18n';
import GroupBinder from 'src/data_binders/group_binder';
import { DataBinderMapper } from 'src/lib/data_binder';
import { reloadElement } from 'src/utils';

const RULES = {
  'js-toggle-subscribe': { isSubscribed: 'toggle' },
  'js-toggle-block': { isBlocked: 'toggle' },
  'js-toggle-favorite': { isFavorited: 'toggle' },
};

// For group members: Toggle Subscribe and Contact Person
$(document)
  .on('ajax:error ajax:beforeSend', '.member', (e) => {
    GroupBinder(e.target, DataBinderMapper(e.target, RULES));
  });

// Group list
$(document)
  .on('ajax:error ajax:beforeSend', '.js-group', (e) => {
    GroupBinder(e.currentTarget, DataBinderMapper(e.target, RULES));
  });

$(document)
  .on('ajax:success:inline', '.js-description-update', function onAjaxSuccessDescriptionUpdate() {
    $(this).trigger('updateGroupDescription:done');
  })
  .on('ajax:error:inline', '.js-description-update', function onAjaxErrorDesciptionUpdate() {
    $(this).trigger('updateGroupDescription:fail');
  });

// Accept / Reject group request to join
// Homepage + Group invites
$(document)
  .on('ajax:beforeSend', '.js-accept-request', function onBeforeSendAcceptRequest() {
    $(this).closest('[data-hide-id]').remove();
  })
  .on('ajax:success', '.js-accept-request', function onSuccessAcceptRequest() {
    $(this).closest('.member').promise().done(() => {
      reloadElement('.js-group-invites');
    });
  });

$(document).on('ajax:success', '.js-toggle-favorite', (e) => {
  reloadElement('.my-groups-wrapper');
  GroupBinder(e.currentTarget, { snackMsg: 'favorite' });
});

$(document).on('ajax:success', '.js-toggle-block', (e) => {
  GroupBinder(e.currentTarget, { snackMsg: 'blocked' });
});

// Toggle group info
$(document).on('click', '.js-toggle-group-info', (e) => {
  e.preventDefault();
  const $parent = $(e.currentTarget).closest('.group');
  const $this = $(e.currentTarget);

  if (!$parent.length > 0) { return; }

  $parent.toggleClass('member-active');
  $parent.hasClass('member-active') ? $this.text(I18n.t('group.more_info')) : $this.text(I18n.t('group.less_info'));
});
