import * as Sentry from '@sentry/browser';
import { CaptureConsole } from '@sentry/integrations';

App.config.env.SENTRY_ON && Sentry.init({
  dsn: App.config.env.SENTRY_DSN,

  environment: App.config.env.RAILS_ENV,
  release: App.config.env.SENTRY_RELEASE,

  // debug: true, // turn this on for debugging...
  ignoreErrors: [/ResizeObserver loop completed with undelivered notifications/],

  tracesSampleRate: 0.9,

  integrations: [
    new Sentry.Integrations.GlobalHandlers({
      onunhandledrejection: false,
    }),
    // This is needed to send console.error to Sentry
    new CaptureConsole({ levels: ['error'] }),
  ],
});

App.config.env.SENTRY_ON && Sentry.configureScope((scope) => {
  scope.setUser({
    id: App.config.user.id,
  });
  if (App.config.runtime.mobile_app_version) {
    scope.setTags({ appVersion: App.config.runtime.mobile_app_version });
  }
});
