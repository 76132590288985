/* eslint-disable import/prefer-default-export */

import copyToClipboard from '@effectivastudio/copy_to_clipboard';
import Snack from '@effectivastudio/snackbar';
import Mobile from '@effectivastudio/turbo_native_adapter';
import ContextMenu from 'src/context_menu';
import Turbolinks from 'turbolinks';
import I18n from 'i18n';

/**
 * Turbolinks actions - 08.07.2020. Update
 *
 * Device    | Visit | Replace     | Restore | Back
 * -----------------------------------------------------------
 * Browser   | yes   | yes         | yes     | history.back();
 * iOS       | yes   | yes         | yes     | custom
 * Android   | yes   | custom      | yes     | history.back();
 *
 * * custom - needs to be implemented on application side
 *
 */

const nativeBack = () => window.history.back();
const turbolinksVisitUrlOrRoot = url => Turbolinks.visit(url || '/', { action: 'replace' });
const hasHistory = () => {
  const { currentVisit } = Turbolinks.controller;
  return currentVisit && currentVisit.referrer.absoluteURL.includes(window.location.host);
};

function commonCopyToClipboard(value) {
  if (Mobile.IS_MOBILE) {
    Mobile.copyToClipboard(value);
  } else {
    copyToClipboard(value);
  }
  Snack({ text: I18n.t('snack.copied') });
}

if (Mobile.IS_MOBILE) {
  /* *** Replace native context menu with proxy menu *** */
  const contextMenuInit = (target) => {
    const shouldReturn = !target.getAttribute('href')
      || target.href.includes('#')
      || target.className.match(/^js-| js-/)
      || target.closest('.js-proxy-menu')
      || target.closest('.proxy-menu');

    if (shouldReturn) { return; }

    new ContextMenu(target);
  };

  $(document).on('touchstart', 'a', (e) => {
    contextMenuInit(e.currentTarget);
  });
}

export function goBack() {
  if (Mobile.IS_MOBILE) {
    Mobile.back();
  } else {
    nativeBack();
  }
}

export function goBackOrDefault(url) {
  // Web
  if (!Mobile.IS_MOBILE) {
    return hasHistory() ? nativeBack() : turbolinksVisitUrlOrRoot(url);
  }

  // Mobile Apps
  if (Mobile.APP_VERSION <= Mobile.APP_OLD_VERSION) {
    return hasHistory() ? nativeBack() : turbolinksVisitUrlOrRoot(url);
  }

  return goBack();
}

$(document)

  .on('click', '.js-copy-to-clipboard', (e) => {
    e.preventDefault();
    commonCopyToClipboard(e.currentTarget.dataset.val);
  })

  .on('click', '.js-copy-link', (e) => {
    e.preventDefault();
    commonCopyToClipboard(e.currentTarget.href);
  })

  .on('click', '.js-share', (e) => {
    e.preventDefault();
    const { url } = e.currentTarget.dataset;
    url && url.length && Mobile.triggerShare(url);
  })

  .on('socialShare-mobileClick', (e) => {
    e.preventDefault();

    // Use composedPath as component is deeply nested in shadow dom
    const socialShareComponent = e.originalEvent.composedPath()[0];

    Mobile.triggerShare(socialShareComponent.url);
  })

  // TODO: After 2.3.4 remove data-history-section in layouts
  .on('click', '.js-back-or-default', (e) => {
    e.preventDefault();

    // Close dropdown
    if (App.dropDownMenuOpen) {
      App.dropDownMenuOpen = false;
      return;
    }

    goBackOrDefault(e.currentTarget.href);
  });
