import CommentBinder from 'src/data_binders/comment_binder';

function commentsMutationObserver(n) {
  if (n.classList && n.classList.contains('card-comment')) {
    const editLink = '> .card-comment-content > .self .js-comment-edit';
    const deleteLink = '> .card-comment-content > .self .js-comment-delete';

    // Show comment actions if current user is comment owner
    if (Number(n.dataset.ownerId) === App.config.user.id) {
      $([editLink, deleteLink].join(','), n).parent().show().closest('.proxy-menu-container')
        .show();
    }

    // Show comment delete action if current user is comment -> card - group admin
    if (n.closest('xs-card').dataset.adminIds.split(',').map(Number).includes(App.config.user.id)) {
      $(deleteLink, n).parent().show().closest('.proxy-menu-container')
        .show();
    }
  }
}

App.mutation_observers.push(commentsMutationObserver);

function loadLazy() {
  const $lazy = $('.js-lazy-html', this);
  const empty = $lazy.data('empty');
  if (empty === true) { return; }
  if ($lazy.data('lazy')) { return; }
  $lazy.data('lazy', 'loading');
  $lazy.length && $lazy.load($lazy.data('url'), function onLoadLazy() {
    $(this).trigger('lazy:success');
    $lazy.data('lazy', 'done');
  });
}

$(document)
  .on('ajax:success', '.card-comment-form-wrapper', function ajaxSuccessCardCommentWrapper(e) {
    const l = e.target.classList;
    if (!l.contains('js-card-comments')) { return; }

    const { response } = e.detail[2];
    const $response = $(response);

    if ($response.find('.has-error:first').length) {
      $(this)
        .html($(response).html())
        .find('form').enableClientSideValidations();
    } else {
      $(this).find('textarea').val('');
      $('.js-auto-size', this).trigger('input').trigger('keyup').trigger('focus');
    }
  })

  // OPTIMIZE: @marko great candidate for mutation observer
  .on('show.bs.collapse', '.collapse-comments', (e) => {
    e.stopPropagation();
    loadLazy.call(e.currentTarget);
  })

  .on('shown.bs.collapse', '.collapse-comments', (e) => {
    $(e.currentTarget).find('form').enableClientSideValidations();
    // e.target.querySelector('.js-card-comments textarea') && e.target.querySelector('.js-card-comments textarea').focus();
  })

  .on('hide.bs.collapse', '.collapse-comments', (e) => {
    $(e.currentTarget).closest('.card-comment').removeClass('active');
  })

  .on('click', '.js-comment-edit', (e) => {
    e.preventDefault();
  })

  .on('click', '.js-comment-discard', (e) => {
    e.preventDefault();
    const parent = e.currentTarget.closest('form');
    const textareas = parent.querySelectorAll('textarea');

    textareas.forEach(t => t.value = '');
    $(textareas[0]).trigger('keyup');
    textareas[0].focus();
  })

  // Load all comments and replies
  .on('lazy:success', '.js-lazy-html', (e) => {
    e.target.querySelectorAll('.js-comment-replies').forEach((el) => {
      if (el.dataset.count === '0') { return; }

      loadLazy.call(el);
      $(el).collapse();
    });
  })

  .on('lazy:success', '.js-comment-replies .js-lazy-html', (e) => {
    e.stopPropagation();
    loadLazy.call(e.currentTarget);
  })

  .on('comment:add', '.card-comment', (e) => {
    CommentBinder(e.currentTarget, { replies_count: '+1' });
  })

  .on('comment:remove', '.card-comment', (e, count) => {
    CommentBinder(e.currentTarget, { replies_count: count });
  });
