import Turbolinks from 'turbolinks';
import { addShadowDomSupportToTurbolinks } from '@crossiety/design';

// Patch must go before Turbolinks.start
addShadowDomSupportToTurbolinks(Turbolinks);

const { scrollToPosition } = Turbolinks.ScrollManager.prototype;

// We need to wait for HTMLElements to be rendered before scrolling
Turbolinks.ScrollManager.prototype.scrollToPosition = function scrollToPositionPatched(position) {
  // First try to scroll immediately to prevent flickering
  scrollToPosition.call(this, position);

  // If it didn't work, try again after next animation frame
  requestAnimationFrame(() => {
    scrollToPosition.call(this, position);
  });
};

Turbolinks.start();

$(document).on('ajax:success content:update', () => {
  // Sometimes controller is not yet initialized so we need check it first
  Turbolinks.controller && Turbolinks.clearCache();
});
