// import 'select2';
import de from 'select2/src/js/select2/i18n/de';
import en from 'select2/src/js/select2/i18n/en';
import fr from 'select2/src/js/select2/i18n/fr';
import I18n from 'i18n';

const languages = { de, en, fr };

App.select2Templates = {
  group_member(member) {
    const groupMemberLabel = member.group_member ? ` <small class="label label-default">${I18n.t('group.groupmate')}</small>` : ''; // cspell:disable-line
    return `<span>${member.text}${groupMemberLabel}</span>`;
  },
};

const baseOptions = () => ({ language: languages[I18n.locale] || de, width: '100%' });

const extendedOptions = selectData => ({
  ajax: {
    url: selectData.url,
    delay: 250,
    data(params) {
      return {
        query: params.term, // search term
        page: params.page,
      };
    },
    processResults(data) {
      if ('results' in data) { return data; }
      return { results: data };
    },
    cache: true,
    tag: true,
  },
  templateResult: App.select2Templates[selectData.appTemplateResult],
  placeholder: selectData.placeholder,
  escapeMarkup(markup) { return markup; }, // let our custom formatter work
  minimumInputLength: ('minimumInputLength' in selectData) ? selectData.minimumInputLength : 2,
});

function setupSelect2(scope) {
  $('.js-select2:visible', scope).each(function setupSelect2each() {
    const options = {
      ...baseOptions(),
      ...(!this.multiple && extendedOptions(this.dataset)),
      ...(scope === 'ion-modal' && { dropdownParent: $('.modal-content') }),
    };

    $(this).select2(options);
  });
}

const setupSelect2Lazy = (scope) => {
  import('select2').then(c => c.default).then(() => setupSelect2(scope));
};

App.mutation_observers.push(setupSelect2Lazy);

document.addEventListener('turbolinks:load', () => {
  setupSelect2Lazy();
});

document.addEventListener('ionModalWillPresent', () => {
  setupSelect2Lazy('ion-modal');
});
